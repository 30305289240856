<template>
  <a-form-model
    :model="query"
    class="search__form"
    :label-col="{ span: 5 }"
    :wrapper-col="{ span: 18 }"
    labelAlign="left"
  >
    <a-row>
      <!-- <a-col :span="6">
        <a-form-model-item label="合作企业名称">
          <a-input placeholder="合作企业名称" v-model="query.title" allowClear></a-input>
        </a-form-model-item>
      </a-col> -->
      <a-col :span="8">
        <a-form-model-item label="中标标题">
          <a-input placeholder="中标标题" v-model="query.title" allowClear></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="16">
        <div class="form_btn_box" v-perms="'search'">
          <a-button style="margin-right: 8px" @click="reset">重置</a-button>
          <a-button type="primary" @click="refresh">查询</a-button>
        </div>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>

export default {
  name: 'ProductHeader',
  data () {
    return {
      query: {}
    }
  },
  methods: {
    reset () {
      this.$set(this, 'query', {})
    },
    refresh () {
      this.$emit('refresh')
    },
    getQuery () {
      return {
        ...this.query
      }
    }
  }
}
</script>

<style lang="less">
.search__form {
  .form_btn_box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
